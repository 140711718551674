import React from "react"

export const useDate = () => {
	const locale = 'en'
	const [today, setDate] = React.useState(new Date()) // Save the current date to be able to trigger an update

	React.useEffect(() => {
		const timer = setInterval(() => {
			// Creates an interval which will update the current data every minute
			// This will trigger a rerender every component that uses the useDate hook.
			setDate(new Date())
		}, 1 * 1000)
		return () => {
			clearInterval(timer) // Return a function to clear the timer so that it will stop being called on unmount
		}
	}, [])

	const day = today.toLocaleDateString(locale, { weekday: 'long' })
	const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`
	const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric', second: '2-digit'})

	return {
		date,
		time,
	}
}

export default useDate
