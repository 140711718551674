import UnitsAssembledMonthBarChart from './charts/UnitsAssembledMonthBarChart'
import TestChart from './charts/UnitsAssembledMonthBarChart'
import Collapsible from './layout/Collapsible/Collapsible'
import PageContent from './layout/PageContent'
import PageTitle from './layout/PageTitle'
import { Clocking } from './pages/Timesheets/Timesheets'

function Homepage() {
	return (
		<>
			<PageTitle>Dashboard</PageTitle>
			<PageContent>
				<div className='home-layout'>
					<div className='hl-left'>
						<Collapsible title={'Announcements'}>
							<section>
								<h1>New Clock-In Feature</h1>
								<p>You now clock in and out directly on our website! Here’s how it works:</p>
								<ol>
									<li>
										Go to the <strong>Clocking</strong> widget on the dashboard.
									</li>
									<li>
										Enter your <strong>Staff ID</strong>.
									</li>
									<li>
										Click <strong>“Clock In”</strong> at the start of your shift.
									</li>
									<li>
										Click <strong>“Clock Out”</strong> when you finish.
									</li>
								</ol>
								<p>
									You can also keep track of your worked hours by visiting the <strong>Timesheets</strong> page.
								</p>
							</section>
						</Collapsible>
						<Collapsible title={'Assembly'}>
							<UnitsAssembledMonthBarChart />
						</Collapsible>
					</div>
					<div className='hl-right'>
						<Collapsible title={'Clocking'}>
							<Clocking />
						</Collapsible>
					</div>
				</div>
			</PageContent>
		</>
	)
}

export default Homepage
