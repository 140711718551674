export const formatDate = (date, includeTime = true) => {
	let options = {
		year: "numeric",
		month: "long",
		day: "numeric",
	}
	if (includeTime) {
		options = {...options, ...{
			hour: "numeric",
			hour12: true,
			minute: "2-digit",
			second: "2-digit",
		}}
	}
	return new Date(date).toLocaleDateString(undefined, options)
}

export function formatCurrency(num) {
	return "£" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}
