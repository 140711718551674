import { toHtml } from '@fortawesome/fontawesome-svg-core'
import useDate from '../../../hooks/useDate'
import Collapsible from '../../layout/Collapsible/Collapsible'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import './Timesheets.css'
import { useCallback, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { formatDate } from '../../../utils/format'
import useFetch from '../../../hooks/useFetch'

const Timesheets = () => {
	const { error, isPending, data: timesheets, reloadData: reloadTimesheets } = useFetch('/api/timesheet')

	return (
		<div id='Timesheets'>
			<PageTitle>Timesheets</PageTitle>
			<PageContent>
				<Collapsible title='Clocking'>
					<Clocking cb={reloadTimesheets}/>
				</Collapsible>
				<Collapsible title='Timesheets'>
					{error}
					<TimesheetsTable data={timesheets} />
				</Collapsible>
			</PageContent>
		</div>
	)
}

const TimesheetsTable = ({ data }) => {
	// Row Data: The data to be displayed.
	const rowData = data
	const [gridApi, setGridApi] = useState(null)

	const onGridReady = useCallback(params => {
		setGridApi(params.api)
	}, [])

	// Apply settings across all columns
	const defaultColDef = useMemo(() => ({
		filter: true,
	}))

	const colorValuesRenderer = ({ value }) => {
		const style = parseInt(value) < 1 ? { color: 'red' } : { color: 'green' }
		return <span className="" style={style}>{value}</span>
	}

	// Column Definitions: Defines the columns to be displayed.
	const [colDefs, setColDefs] = useState([
		{ headerName: 'Timesheet ID', field: 'timesheet_id' },
		{ headerName: 'User ID', field: 'user_id' },
		{ headerName: 'Name', field: 'user_name' },
		{
			headerName: 'Clock In Date/Time',
			field: 'clock_in_time',
			cellRenderer: ({ value }) => formatDate(value),
		},
		{
			headerName: 'Clock Out Date/Time',
			field: 'clock_out_time',
			cellRenderer: ({ value }) => {
				if (value) return formatDate(value)
				return 'Pending...'
			},
		},
		{
			headerName: 'Duration',
			valueGetter: params => {
				const { clock_in_time, clock_out_time } = params.data

				// If there's no endDate or it's empty, display "Pending"
				if (!clock_out_time) {
					return 'Pending...'
				}

				const start = new Date(clock_in_time)
				const end = new Date(clock_out_time)

				// Calculate the difference in milliseconds
				const diffMs = end - start

				// Convert ms to hours and minutes
				const hours = Math.floor(diffMs / (1000 * 60 * 60))
				const minutes = Math.floor((diffMs / (1000 * 60)) % 60)

				// Return a formatted string, e.g. "5h 45m"
				return `${hours}h ${minutes}m`
			},
		},
		{
			headerName: 'Payable Hours',
			valueGetter: params => {
				const { clock_in_time, clock_out_time } = params.data;
			
				// If there's no clock_out_time or it's empty, display "Pending..."
				if (!clock_out_time) {
					return 'Pending...';
				}
			
				const start = new Date(clock_in_time);
				const end = new Date(clock_out_time);
			
				// Calculate the difference in milliseconds
				const diffMs = end - start;
			
				// Convert milliseconds to hours
				let hours = diffMs / (1000 * 60 * 60);
			
				// Return the rounded hours, formatted to 2 decimal places
				return hours.toFixed(2);
			},
		},
		{
			headerName: 'Status',
			field: 'status',
			cellRenderer: colorValuesRenderer,
		},
	])

	// Sizing settings for the grid
	const autoSizeStrategy = {
		type: 'fitGridWidth',
		defaultMinWidth: 100,
		columnLimits: [
			{ colId: 'clock_in_time', minWidth: 300 },
			{ colId: 'clock_out_time', minWidth: 300 },
			{ colId: 'status', minWidth: 150 },
			{ colId: 'user_id', minWidth: 100 },
			{ colId: 'user_name', minWidth: 300 },
		],
	}

	return (
		<div className='ag-theme-quartz' style={{ height: 400 }}>
			<AgGridReact
				rowData={rowData}
				columnDefs={colDefs}
				pagination={true}
				paginationAutoPageSize={true}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				autoSizeStrategy={autoSizeStrategy}
			/>
		</div>
	)
}

const Clocking = ({ cb }) => {
	const [staffId, setStaffId] = useState()
	const [message, setMessage] = useState('')

	const handleStaffIdChange = e => {
		setStaffId(e.target.value)
	}

	const handleClockInClick = async () => {
		if (!staffId) {
			setMessage('Please enter a Staff ID')
			return
		}

		try {
			const response = await fetch('/api/timesheet/clock-in', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ userId: staffId }),
			})

			if (!response.ok) {
				// If the response is not OK (e.g., 400 or 500), parse the error message
				const errorData = await response.json()
				setMessage(errorData.error || 'Failed to clock in. Please try again.')
				return
			}

			// If the request was successful, parse the JSON response
			const data = await response.json()
			setMessage(`Timesheet created successfully for user: ${data.userName}`)
		} catch (error) {
			console.error('Error clocking in:', error)
			setMessage('An error occurred while clocking in.')
		}

		// Clear the staffId after clocking in
		setStaffId('')
        cb && cb()
	}

	const handleClockOutClick = async () => {
		if (!staffId) {
			setMessage('Please enter a Staff ID')
			return
		}

		try {
			const response = await fetch('/api/timesheet/clock-out', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ userId: staffId }),
			})

			if (!response.ok) {
				const errorData = await response.json()
				setMessage(errorData.error || 'Failed to clock out. Please try again.')
				return
			}

			const data = await response.json()
			setMessage(`Timesheet clocked out successfully for user: ${data.userName}`)
		} catch (error) {
			console.error('Error clocking out:', error)
			setMessage('An error occurred while clocking out.')
		}

		setStaffId('')
        cb && cb()
	}

	const { date, time } = useDate()
	return (
		<div className='Clocking'>
			<p className='clocking-title'>Clocking</p>
			<p>Current Time</p>
			<p className='time'>
				{date} - {time}
			</p>
			<p>{message}</p>
			<label>Enter staff ID:</label>
			<input type='number' value={staffId} onChange={handleStaffIdChange} />
			<button onClick={handleClockInClick}>Clock in</button>
			<button className='negative' onClick={handleClockOutClick}>
				Clock out
			</button>
		</div>
	)
}

export default Timesheets
export { Clocking }
